import type {NextPage} from 'next';

// Components
import withAuthenticate from '@Components/authenticate';
import Section from '@Src/layouts/Section';
import Home from '@Src/layouts/Home';

const HomePage: NextPage = () => (
    <Section>
        <Home />
    </Section>
);

export default withAuthenticate(HomePage);

// export const getServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
//     const result = {
//         props: {}
//     };
//
//     return withNoAuthServer({store, result, context});
// });
