// Libraries
import React, {useState, useMemo} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import classnames from 'classnames';
import {Collapse} from 'antd';
import {UserOutlined, PhoneOutlined, MailOutlined} from '@ant-design/icons';

import Images from '@Src/constants/images';
import SubscribeTrialForm from '@Components/subscribe-trial-form';

// Styles
import 'animate.css';
import styles from './Home.module.scss';

const {Panel} = Collapse;

const contents = [
    {
        id: 1,
        title: 'QUẢN LÝ ĐỘI NGŨ BÁN HÀNG, CTV HIỆU QUẢ',
        video: 'https://www.youtube.com/embed/MedntrssDRs',
        ytId: 'MedntrssDRs',
        description:
			'Tiết kiệm 23% chi phí quản lý nhân sự và Marketing. Quy trình tinh gọn với Admin và App đồng bộ.'
    },
    {
        id: 2,
        title: 'KHÔNG LO THIẾU HỤT NHÂN VIÊN BÁN HÀNG',
        video: 'https://www.youtube.com/embed/TTYjFCf80QM',
        ytId: 'TTYjFCf80QM',
        description: 'Tiếp cận với nhiều CTV hơn với App mang thương hiệu của doanh nghiệp'
    },
    {
        id: 3,
        title: 'LÊN ĐƠN HÀNG KHẮP MỌI NƠI',
        video: 'https://www.youtube.com/embed/dOCRd_Y-KcQ',
        ytId: 'dOCRd_Y-KcQ',
        description: 'Lên đơn hàng dễ dàng trực tiếp trên App và quản lý tối ưu hơn với hệ thống Admin'
    },
    {
        id: 4,
        title: 'BIẾN KHÁCH HÀNG THÀNH NHÀ BÁN HÀNG',
        video: 'https://www.youtube.com/embed/vhBwZtXTzhI',
        ytId: 'vhBwZtXTzhI',
        description:
			'Tiết kiệm 23% chi phí quản lý nhân sự và Marketing. Quy trình tinh gọn với Admin và App đồng bộ.'
    },
    {
        id: 5,
        title: 'KIỂM SOÁT NHÂN VIÊN ĐI THỊ TRƯỜNG',
        video: 'https://www.youtube.com/embed/v9mWFh1qOmM',
        ytId: 'v9mWFh1qOmM',
        description: 'Tiếp cận với nhiều CTV hơn với App mang thương hiệu của doanh nghiệp'
    }
];

const questions = [
    {
        id: 1,
        title: 'Ý Nghĩa Các Chức Năng Của APP',
        children: [
            {
                id: '1-1',
                title: 'Giới thiệu về Ứng dụng',
                detail: '',
                video: 'https://www.youtube.com/embed/YW2EYmFvJBo'
            },
            {
                id: '1-2',
                title: 'Báo Cáo Doanh Thu',
                detail: '',
                video: 'https://www.youtube.com/embed/GgnxqjUG_KU'
            },
            {
                id: '1-3',
                title: 'Báo Cáo Tuyển Dụng',
                detail: '',
                video: 'https://www.youtube.com/embed/xZ8n_HcPQrQ'
            },
            {
                id: '1-4',
                title: 'Đào Tạo',
                detail: '',
                video: 'https://www.youtube.com/embed/XnQudApvWws'
            },
            {
                id: '1-5',
                title: 'Lịch Trình',
                detail: '',
                video: 'https://www.youtube.com/embed/kTGCaeWfJws'
            }
        ]
    },
    {
        id: 2,
        title: 'Hướng Dẫn Sử Dụng Trong Admin',
        children: [
            {
                id: '2-1',
                title: 'Tiếp Thị Liên Kết',
                detail: '',
                video: 'https://www.youtube.com/embed/GmibUpigN_w'
            },
            {
                id: '2-2',
                title: 'Thêm NCC và Nhập Kho',
                detail: '',
                video: 'https://www.youtube.com/embed/R4qxCmulN1Y'
            },
            {
                id: '2-3',
                title: 'Khách hàng',
                detail: '',
                video: 'https://www.youtube.com/embed/ff_xaFNkU6g'
            }
        ]
    }
];

const Home = () => {
    const [activeVideo, setActiveVideo] = useState(contents[0].id);
    const [activeQuestion, setActiveQuestion] = useState(questions[0].children[0].id);
    const questionsObject = useMemo(
        () =>
            questions?.reduce(
                (acc, curr) => ({
                    ...acc,
                    ...(curr?.children?.reduce(
                        (accQuestions, question) => ({
                            ...accQuestions,
                            [question?.id]: question
                        }),
                        {}
                    ) || {})
                }),
                {}
            ),
        [questions]
    );

    const activeQuestionObject = useMemo(
        () => questionsObject[activeQuestion] || {title: '', detail: ''},
        [activeQuestion, questionsObject]
    );

    return (
        <div className="container bg-cover overflow-x-hidden max-w-screen-xl">
            <section
                id="introduction"
                className="mt-5 pt-16 flex flex-col gap-16 sm:mt-16 sm:flex-row sm:gap-5"
            >
                <div className={classnames('w-full flex flex-col items-center justify-center sm:w-1/2')}>
                    <div
                        className={classnames(
                            'animate__animated animate__fadeInLeft relative flex flex-col gap-y-5 px-10 items-center sm:items-start'
                        )}
                    >
                        <p className="font-['Oswald'] font-bold text-[30px] text-center sm:text-left md:text-[35px] 2xl:text-[45px]">
							Giải pháp quản lý bán hàng và Cộng tác viên
                        </p>
                        <p className="text-center sm:text-left md:text-[18px] 2xl:text-[28px]">
							Mục tiêu trở thành công ty công nghệ hàng đầu tại Việt Nam. Tạo ra giải pháp bán hàng
							và CTV, tối ưu chi phí vận hành cho doanh nghiệp
                        </p>
                        <Link href="/#register">
                            <a
                                className="rounded-md px-4 py-2 text-white bg-blue-700
									hover:text-white hover:bg-blue-500 w-max md:text-[20px] 2xl:text-[24px]"
                            >
								Đăng kí dùng thử ngay
                            </a>
                        </Link>
                    </div>
                </div>
                <div
                    className={classnames(
                        'w-full flex flex-col items-center justify-center px-10 sm:pl-0 sm:w-1/2'
                    )}
                >
                    <div className="animate__animated animate__fadeInRight">
                        <Image src={Images.INTRO_LANDING_PAGE} alt="logo-landing-page" />
                    </div>
                </div>
            </section>

            <section id="service" className="mt-5 pt-16 2xl:pb-10">
                <div className="animate__animated animate__zoomIn">
                    <div className="flex flex-col gap-y-10 justify-center items-center">
                        <div className="font-['Oswald'] text-[24px] font-bold md:text-[28px] uppercase">
							Mô hình giải pháp Eoty
                        </div>
                        <div
                            className={classnames(
                                'relative rounded-md px-10 w-full h-[300px] md:h-[400px] lg:h-[540px] 2xl:w-[85%]',
                                styles.video
                            )}
                        >
                            <iframe
                                className="relative z-[1]"
                                width="100%"
                                height="100%"
                                src="https://www.youtube.com/embed/YEjZjJxcmuI"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section
                id="about"
                className={classnames(
                    'flex w-full flex-row mt-5 pt-16 pb-8 bg-gray-100 flex flex-col justify-center gap-y-10 md:flex-row md:items-end',
                    styles.solution
                )}
            >
                <div className="md:w-2/3 animate__animated animate__fadeInLeft">
                    <div className="flex flex-col px-10 gap-y-10 items-center md:px-0 sm:gap-y-5 sm:justify-start sm:items-center">
                        <div className="font-['Oswald'] text-[24px] font-bold md:text-[28px] uppercase">
							Sự thay đổi khi chuyển đổi số
                        </div>
                        <div
                            className={classnames(
                                'w-full flex justify-end h-[250px] md:px-10 md:h-[340px] lg:px-10 lg:h-[400px] 2xl:h-[400px]	',
                                styles.video
                            )}
                        >
                            <iframe
                                width="100%"
                                src={contents[activeVideo - 1].video}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
                <div className="md:w-1/3 animate__animated animate__fadeInRight">
                    <div className="w-full h-full px-10 flex flex-col gap-2.5 sm:px-32 md:px-10 md:pl-0 lg:pr-10 lg:gap-5">
                        <div className="border rounded-md flex flex-col gap-3 p-2 h-[300px] overflow-y-auto sm:h-[380px] lg:h-[400px]">
                            {contents?.map((content, index) => (
                                <div
                                    aria-hidden
                                    key={index}
                                    className={classnames(
                                        'flex w-full cursor-pointer p-3 gap-2 rounded hover:bg-gray-300',
                                        {
                                            'bg-gray-300': activeVideo === content?.id
                                        }
                                    )}
                                    onClick={() => setActiveVideo(content?.id)}
                                >
                                    <span className="w-1/2 flex h-[80px] lg:h-[100px] bg-white justify-center items-center text-5xl font-bold overflow-hidden">
                                        <img alt="" src={`https://img.youtube.com/vi/${content.ytId}/0.jpg`} />
                                    </span>
                                    <span className="w-1/2 flex items-center pr-5">{content.title}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section
                id="support"
                className="flex w-full md:flex-row md:flex-row-reverse flex-col mt-5 pt-16 gap-y-10"
            >
                <div className="flex md:w-2/3 animate__animated animate__fadeInLeft">
                    <div className="w-full px-10 md:order-2">
                        <div className="font-['Oswald'] text-[24px] font-bold mb-5 text-center md:text-[28px] uppercase">
							Hướng dẫn tổng quan về Eoty
                        </div>
                        <div className="rounded border p-5">
                            <div
                                className={classnames(
                                    'text-white text-xl h-[200px] font-bold mb-5 border-b-2 border-white sm:h-[400px]',
                                    styles.video
                                )}
                            >
                                <iframe
                                    className="relative z-[1]"
                                    width="100%"
                                    height="100%"
                                    src={activeQuestionObject?.video}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                            <div>{activeQuestionObject?.detail}</div>
                        </div>
                    </div>
                </div>
                <div className="flex md:w-1/3 animate__animated animate__fadeInRight">
                    <div className="w-full px-10 sm:px-32 md:pl-10 md:pr-0 md:order-1 md:pt-20">
                        <Collapse
                            defaultActiveKey={[questions[0].id]}
                            expandIcon={() => null}
                            className="bg-transparent border-0"
                        >
                            {questions.map((question) => (
                                <Panel
                                    header={question?.title}
                                    key={question?.id}
                                    className={classnames(
                                        'border-b last:border-b-0 md:text-[16px]',
                                        styles['collapse-item'],
                                        {
                                            [styles.selected]: activeQuestion.indexOf(question?.id?.toString()) === 0
                                        }
                                    )}
                                >
                                    <ul className="bg-transparent">
                                        {question?.children?.map((option) => (
                                            <li
                                                aria-hidden="true"
                                                key={option.id}
                                                className={classnames('cursor-pointer', {
                                                    [styles.active]: option?.id === activeQuestion
                                                })}
                                                onClick={() => setActiveQuestion(option?.id)}
                                            >
                                                {option.title}
                                            </li>
                                        ))}
                                    </ul>
                                </Panel>
                            ))}
                        </Collapse>
                    </div>
                </div>
            </section>

            <section id="register" className="mt-5 pt-16 px-10 flex justify-center items-center">
                <div className="animate__animated animate__headShake w-full">
                    <SubscribeTrialForm />
                </div>
            </section>
        </div>
    );
};

export default Home;
