import {gql} from '@apollo/client';

export const SUBSCRIBE_CUSTOMER_TRIAL_MUTATION = gql`
    mutation createNetworkTrial($input: CreateNetworkTrialInputDto!) {
        createNetworkTrial(input: $input) {
            id
            fullName
            siteName
            status
        }
    }
`;
