import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {GRAPHQL_URL} from '@Src/constants';

const httpLink = createHttpLink({
    uri: GRAPHQL_URL
});

const authLink = setContext((_, {headers}) => {
    // const reduxStore = getOrCreateStore();
    // const {accessToken = ''} = reduxStore.getState();
    const accessToken = '';

    // Return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : ''
        }
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

export default client;
