// Libraries
import React, {useEffect} from 'react';
import classnames from 'classnames';
import {message} from 'antd';
import {useForm, SubmitHandler} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import styles from './styles.module.scss';
import {MailOutlined, PhoneOutlined, UserOutlined} from '@ant-design/icons';

// GraphQL Query
import appoloClient from '@Utils/appolo-client';
import {SUBSCRIBE_CUSTOMER_TRIAL_MUTATION} from '@Utils/graphql';

interface Props {
	[key: string]: unknown;
}

interface IFormInputs {
	fullName: string;
	phoneNumber: string | number;
	email: string;
}

const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup
    .object({
        fullName: yup.string().required(),
        phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid').required(),
        email: yup.string().email().required()
    })
    .required();

const SubscribeTrialForm: React.FC<Props> = () => {
    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitSuccessful},
        reset
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({
                fullName: '',
                phoneNumber: '',
                email: ''
            });
        }
    }, [isSubmitSuccessful, reset]);

    const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
        try {
            const response: Record<string, any> = await appoloClient.mutate({
                mutation: SUBSCRIBE_CUSTOMER_TRIAL_MUTATION,
                variables: {
                    input: {
                        ...data
                    }
                }
            });

            if (response?.data?.createNetworkTrial?.id) {
                message.success({
                    content: 'Đăng ký thành công, chờ chúng tôi liên hệ nhé !',
                    style: {
                        fontSize: '14px',
                        fontWeight: 'normal'
                    }
                });
            } else {
                message.error({
                    content: 'Đăng ký không thành công, hãy liên hệ hotline nhé !',
                    style: {
                        fontSize: '14px',
                        fontWeight: 'normal'
                    }
                });
            }

            reset({...data});
        } catch (err) {
            message.error({
                content: 'Đăng ký không thành công, hãy liên hệ hotline nhé !',
                style: {
                    fontSize: '14px',
                    fontWeight: 'normal'
                }
            });
        }
    };

    return (
        <form className={'w-full'} onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full bg-[#939b93] p-5 rounded-xl shadow-lg lg:w-[75%] lg:p-5 m-auto">
                <div className="uppercase text-center text-white text-[20px] sm:text-2xl md:text-3xl font-bold mb-5">
					Đăng kí dùng thử ngay
                </div>
                <div className={classnames(styles.fields, 'flex gap-5 flex-col md:grid')}>
                    <div className={styles.name}>
                        <UserOutlined />
                        <input {...register('fullName')} className="grow" placeholder="Họ và tên" />
                        <p className={'before:content-[⚠] inline text-red-600 text-sm'}>
                            {errors.fullName ? 'Hãy nhập họ tên' : ''}
                        </p>
                    </div>
                    <div className={styles['phone-number']}>
                        <PhoneOutlined />
                        <input {...register('phoneNumber')} className="grow" placeholder="Số điện thoại" />
                        <p className={'before:content-[⚠] inline text-red-600 text-sm'}>
                            {errors.phoneNumber ? 'Hãy nhập đúng số điện thoại' : ''}
                        </p>
                    </div>
                    <div className={styles.email}>
                        <MailOutlined />
                        <input {...register('email')} className="grow" placeholder="Địa chỉ email" />
                        <p className={'before:content-[⚠] inline text-red-600 text-sm'}>
                            {errors.email ? 'Email không hợp lệ' : ''}
                        </p>
                    </div>
                    <button
                        type="submit"
                        className="rounded bg-white text-gray-500 hover:text-white hover:bg-gray-300
								flex items-center justify-center
								cursor-pointer px-8 py-3 md:text-[18px]"
                    >
						Đăng kí ngay
                    </button>
                </div>
            </div>
        </form>
    );
};

export default SubscribeTrialForm;
